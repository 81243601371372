import React from "react"
import HomePage from "./homePage"
import "../style/index.scss";

const IndexPage = () => (
  <>
        <HomePage/>
  </>
);

export default IndexPage
